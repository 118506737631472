import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Modal,
  Form,
  FormControl,
  InputGroup,
  Spinner
} from 'react-bootstrap';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { AddLiquidity as textLang } from 'staticData/languages';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AppContext from 'context/Context';
import { cleanCache } from 'services/cache';
import { coinsData, currencyMap } from 'services/coins/common';
import { walletImgRender } from 'services/coins/components';
import SimpleBarReact from 'simplebar-react';
import InfoPopover from 'components/utilities/InfoPopover';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';
import { botGeneralInfo } from 'staticData/common';
import { breakpoints } from 'helpers/utils';
import { trackEvent } from 'services/externals/mixpanel';

const AddLiquidityModal = ({
  modalShow,
  setModalShow,
  setAddedLiquidity,
  botID,
  type = 'bot',
  multiBot = false,
  bot,
  botList = [],
  isForced = false,
  addCapitalClickEvent = null
}) => {
  const {
    config: { lang, isReal, currency }
  } = useContext(AppContext);
  const [coinsAmount, setCoinsAmount] = useState({});
  const [checkedBots, setCheckedBots] = useState([]);
  const [error, setError] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [showEndMessage, setShowEndMessage] = useState(false);
  const [liquidityData, setLiquidityData] = useState(null);
  const [noManagedUnderLimitError, setNoManagedUnderLimitError] = useState([]);

  const handleNewAmount = (coin, amount, { min = 0, max } = {}) => {
    setError('');
    let newAmount = Number(amount);
    if (newAmount < min) {
      newAmount = min;
    } else if (max !== undefined && newAmount > max) {
      newAmount = max;
    }
    setCoinsAmount(prevAmounts => ({
      ...prevAmounts,
      [coin]: {
        quantity: amount === '' ? '' : newAmount,
        equivalent: newAmount * liquidityData.coins[coin].price
      }
    }));
  };

  const transformObject = inputObject => {
    let outputObject = { coins: {} };
    for (let coin in inputObject) {
      outputObject.coins[coin] = Number(inputObject[coin].quantity);
    }
    return outputObject;
  };

  const sumEquivalent = obj => {
    // Reduce the obj to get the initial sum of equivalents
    const initialSum = Object.values(obj).reduce(
      (sum, { equivalent }) => sum + equivalent,
      0
    );

    // Calculate the sum of the equivalents for the bots selected in checkedBots
    const checkedBotsSum = checkedBots.reduce((sum, botId) => {
      // Find the corresponding bot in botList
      const bot = botList.find(bot => bot.bot_details.id === botId);

      // If the bot is found and the currency is valid, add its equivalent value
      if (bot && bot.last_stat && bot.last_stat.eqv && currencyMap[currency]) {
        const equivalentValue = bot.last_stat.eqv[currencyMap[currency]];
        return sum + (equivalentValue || 0);
      }

      return sum;
    }, 0);
    // Return the total sum: initial sum + sum of checkedBots
    return initialSum + checkedBotsSum;
  };

  const errorManagement = () => {
    let localError = '';
    const coinsUnderLimit = [];
    if (
      checkedBots.length === 0 &&
      sumEquivalent(coinsAmount) < Number(liquidityData.usd_equiv.min)
    ) {
      localError = `${textLang.reasons['min'][lang]}. Min: ${
        liquidityData?.usd_equiv?.min || 0
      }`;
    } else if (
      checkedBots.length === 0 &&
      sumEquivalent(coinsAmount) > Number(liquidityData.usd_equiv.max)
    )
      localError = `${
        textLang.reasons[liquidityData.usd_equiv.reason]?.[lang] ||
        textLang.reasons['no_data'][lang]
      }. Min: ${liquidityData?.usd_equiv?.min || 0} - Max: ${
        liquidityData?.usd_equiv?.max || 100
      }`;
    else {
      for (const [coin, data] of Object.entries(coinsAmount)) {
        // verify if equivalent is under 10 and the coin is not in bot.assets
        if (
          data.equivalent > 0 &&
          data.equivalent < 10 &&
          !bot.assets.includes(coin)
        ) {
          coinsUnderLimit.push(coin);
        }
      }
      if (coinsUnderLimit.length > 0)
        localError = textLang.minImportList[lang] + coinsUnderLimit.join(', ');
    }
    setNoManagedUnderLimitError(coinsUnderLimit);
    setError(localError);
    return localError;
  };

  const changeAllocation = async output => {
    let changeAllocationResp = await endpointInterfaceV2({
      internalEndpoint: 'changeAllocation',
      httpMethod: 'post',
      usingCredentials: true,
      pathParams: {
        bot_id: botID
      },
      bodyParams: output
    });
    if (changeAllocationResp.validResponse) {
      trackEvent('portfolio_add_liquidity', {
        parameters: output,
        event: isForced ? addCapitalClickEvent : 'Buttons in dashboard'
      });
      await cleanCache(0);
      setShowEndMessage(true);
    }
  };

  const convertDate = dateStr => {
    if (isNaN(Date.parse(dateStr))) {
      console.error(`Invalid date: ${dateStr}`);
      return dateStr;
    }
    let date = new Date(dateStr);
    let formattedDate = date.toLocaleDateString(lang, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
    return formattedDate;
  };

  const handleAddLiquidity = async () => {
    let localError = errorManagement();
    if (localError) return;
    let output = transformObject(coinsAmount);
    setShowLoading(true);
    await changeAllocation({ ...output, bots_to_replace: checkedBots });
    setShowLoading(false);
  };

  const handleCloseModal = () => {
    if (showEndMessage) setAddedLiquidity();
    if (multiBot) setLiquidityData(null);
    setModalShow(false);
  };

  // const handleBotClick = botID => {
  //   setCheckedBots(prevCheckedBots =>
  //     prevCheckedBots.includes(botID)
  //       ? prevCheckedBots.filter(id => id !== botID)
  //       : [...prevCheckedBots, botID]
  //   );
  // };

  const getBotDataById = id => {
    for (const key in botGeneralInfo) {
      if (botGeneralInfo[key].id === id) {
        return { type: key, name: botGeneralInfo[key].name };
      }
    }
    return 'noBot';
  };

  const allocationData = async () => {
    let allocationResp = await endpointInterfaceV2({
      internalEndpoint: 'changeAllocation',
      httpMethod: 'get',
      usingCredentials: true,
      pathParams: {
        bot_id: botID
      }
    });
    if (allocationResp.validResponse) {
      // Decrease 0.5% (max 5$) from the all the coins
      const percent = 0.5;
      const maxDecrease = 5;
      var localCoins = {};
      if (allocationResp.data.coins) {
        Object.entries(allocationResp.data.coins).forEach(
          ([coin, coinData]) => {
            let localMax = Math.max(
              coinData.max * coinData.price * (1 - percent / 100),
              coinData.max * coinData.price - maxDecrease
            );
            localMax /= coinData.price;
            localCoins[coin] = {
              ...coinData,
              max: localMax
            };
          }
        );
        allocationResp.data.coins = localCoins;
      }
      let sortedData = {};
      // Sort the coins by max and price
      if (allocationResp.data.coins) {
        const sortedDataArray = Object.entries(allocationResp.data.coins).sort(
          (a, b) => {
            const valueA = a[1].max * a[1].price;
            const valueB = b[1].max * b[1].price;
            return valueB - valueA;
          }
        );
        sortedData = Object.fromEntries(sortedDataArray);
        allocationResp.data.coins = sortedData;
      }
      setLiquidityData(allocationResp.data);
    } else setLiquidityData({ allowed: false, reason: 'no_data' });
  };

  useEffect(async () => {
    if (modalShow && !liquidityData) allocationData();
    if (botList.length > 0) {
      const allBotIDs = botList.map(bot => bot.bot_details.id);
      setCheckedBots(allBotIDs);
    }
  }, [modalShow]);

  return (
    <Modal
      show={modalShow}
      onHide={handleCloseModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        {liquidityData ? (
          <Modal.Title id="contained-modal-title-vcenter">
            {showEndMessage
              ? textLang.endTitle[lang]
              : liquidityData.allowed
              ? botList.length > 0
                ? textLang.titleBots[lang]
                : textLang.title[lang]
              : textLang.notAllowed[lang]}
          </Modal.Title>
        ) : (
          isReal && (
            <Modal.Title id="contained-modal-title-vcenter">
              {textLang.loadingTitle[lang]}
            </Modal.Title>
          )
        )}
      </Modal.Header>
      {showEndMessage ? (
        <Modal.Body>{textLang.endMessage[lang]}</Modal.Body>
      ) : liquidityData ? (
        !liquidityData.allowed ? (
          <Modal.Body>
            {liquidityData.reason === 'bot_not_active'
              ? type === 'bot'
                ? textLang.reasons[liquidityData.reason]?.[lang]
                : textLang.reasons['portfolio_not_active']?.[lang]
              : textLang.reasons[liquidityData.reason]?.[lang] ||
                textLang.reasons['no_data'][lang]}
            {liquidityData.reason === 'wait_for_cooldown' && (
              <b>{convertDate(liquidityData.cooldown_end)}</b>
            )}
          </Modal.Body>
        ) : (
          <>
            <Modal.Body>
              {botList.length > 0 && (
                <h6
                  className="mb-3"
                  dangerouslySetInnerHTML={{ __html: textLang.subTitle[lang] }}
                />
              )}
              <SimpleBarReact
                style={{
                  maxHeight: `${250}px`,
                  width: '100%'
                }}
              >
                {Object.entries(liquidityData?.coins).map(
                  ([coin, coinData], index) => (
                    <Row
                      key={index}
                      className="pb-2 d-flex align-items-center"
                      style={{ width: '100%' }}
                    >
                      <Col
                        xs={3}
                        className="d-flex align-items-center justify-content-center flex-row"
                      >
                        <Avatar src={coinsData[coin].img}></Avatar>
                      </Col>
                      <Col className="ms-0 ps-0">
                        <h6 className="mb-1 text-secondary position-relative">
                          {`${textLang.amount[lang]} (${coin}) ${
                            coinsAmount[coin] &&
                            coinsAmount[coin].quantity !== 0
                              ? '- $' +
                                coinsAmount[coin]?.equivalent.toFixedDown(2)
                              : ''
                          }`}
                        </h6>
                        {!bot?.assets?.includes(coin) &&
                          coinData.max === 0 &&
                          coinData.reason === 'not_managed_under_10' && (
                            <h6 className="mb-0 text-info">
                              {textLang.mi10Dollar[lang]}
                            </h6>
                          )}
                        {noManagedUnderLimitError.includes(coin) && (
                          <h6 className="mb-0 text-danger">
                            {textLang.minImport[lang]}
                          </h6>
                        )}
                        <InputGroup className="ms-0 p-1">
                          <FormControl
                            aria-label="Amount"
                            type="number"
                            onChange={e =>
                              handleNewAmount(coin, e.target.value, {
                                max: coinData.max
                              })
                            }
                            value={
                              coinsAmount[coin]
                                ? coinsAmount[coin]?.quantity === ''
                                  ? ''
                                  : Number(coinsAmount[coin]?.quantity)
                                : 0
                            }
                            disabled={
                              liquidityData?.coins[coin]?.max?.toFixedDown(
                                coinsData[coin].decimalPrecision
                              ) === 0
                            }
                            min={0}
                            max={
                              liquidityData?.coins[coin]?.max?.toFixedDown(
                                coinsData[coin].decimalPrecision
                              ) || 0
                            }
                          />
                        </InputGroup>
                        <Form.Range
                          step={10 ** -coinsData[coin].decimalPrecision}
                          disabled={
                            liquidityData?.coins[coin]?.max?.toFixedDown(
                              coinsData[coin].decimalPrecision
                            ) === 0
                          }
                          min={0}
                          max={
                            coinData.max.toFixedDown(
                              coinsData[coin].decimalPrecision
                            ) || 0
                          }
                          value={coinsAmount[coin]?.quantity || 0}
                          onChange={e =>
                            handleNewAmount(coin, e.target.value, {
                              max: coinData.max
                            })
                          }
                          style={{
                            marginLeft: '5px',
                            width: '97%'
                          }}
                          className="amountRange"
                        />
                      </Col>
                    </Row>
                  )
                )}
              </SimpleBarReact>
              {botList.length > 0 && (
                <div className="ps-sm-5">
                  <hr />
                  <h5>
                    {textLang.botsTitle_add[lang]}
                    <InfoPopover
                      Tag="span"
                      Text={textLang.infoBots[lang]}
                      className="ms-1"
                    />
                  </h5>
                  {botList.map((bot, index) => (
                    <Row
                      key={index}
                      className="ps-1 mx-1 mb-2 align-items-center"
                      // onClick={() => handleBotClick(bot.bot_details.id)}
                    >
                      <Col
                        as={Flex}
                        alignItems="center"
                        justifyContent="center"
                        className="px-0"
                      >
                        <Form.Check
                          type="checkbox"
                          id={`bot-${index}`}
                          checked={checkedBots.includes(bot.bot_details.id)}
                        />
                        <LottieBotLazy
                          type={getBotDataById(bot.bot_details.type).type}
                          loop={true}
                          style={{
                            width:
                              window.innerWidth < breakpoints['sm']
                                ? '100%'
                                : '50%'
                          }}
                        />
                      </Col>
                      <Col as={Flex} justifyContent="center">
                        <div style={{ width: 'min-content' }}>
                          <h5 className="mb-0">{bot.bot_details.name}</h5>
                          <small>
                            {getBotDataById(bot.bot_details.type).name || ''}
                          </small>
                          <div className="mt-2">
                            {walletImgRender(bot.bot_details.assets, 'ms-0')}
                          </div>
                        </div>
                      </Col>
                      <Col className="text-center">
                        <p className="mt-2 mb-0 fs--1">
                          {textLang.managed[lang]}
                        </p>
                        <p className="mt-2">
                          <b className={'text-dark'}>
                            {currency}
                            {bot.last_stat.eqv[currencyMap[currency]].toFixed(
                              2
                            )}
                          </b>
                        </p>
                      </Col>
                    </Row>
                  ))}
                </div>
              )}
              <Row className="pb-2 ">
                <Col className="ms-0 ps-0 d-flex justify-content-center">
                  <h4 className="mb-1 text-secondary">
                    {`${textLang.total[lang]}: ${currency}${sumEquivalent(
                      coinsAmount
                    ).toFixedDown(2)}`}
                  </h4>
                </Col>
              </Row>
              {error && (
                <Row>
                  <Col>
                    <h6 className="text-danger">{error}</h6>
                  </Col>
                </Row>
              )}
              <small>
                {type === 'bot'
                  ? textLang.coolDown[lang]
                  : textLang.coolDownPortfolio[lang]}
              </small>
            </Modal.Body>
            <Modal.Footer>
              <Flex className="col-6 m-0" style={{ justifyContent: 'start' }}>
                <IconButton
                  onClick={handleCloseModal}
                  variant="outline-secondary"
                  className="me-2 mb-1 "
                  icon="arrow-left"
                  iconAlign="left"
                  transform="shrink-3"
                >
                  {textLang.goBack[lang]}
                </IconButton>
              </Flex>
              <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
                <IconButton
                  onClick={() => handleAddLiquidity()}
                  variant={'outline-info'}
                  className="me-2 mb-1 "
                  icon={showLoading ? '' : 'plus-circle'}
                  iconAlign="left"
                  transform="shrink-3"
                  disabled={sumEquivalent(coinsAmount) <= 0 || showLoading}
                >
                  {showLoading ? (
                    <Spinner
                      style={{ width: '1rem', height: '1rem' }}
                      className="mx-2"
                    />
                  ) : (
                    textLang.confirm[lang]
                  )}
                </IconButton>
              </Flex>
            </Modal.Footer>
          </>
        )
      ) : (
        <Modal.Body>
          <Col className="text-center px-2">
            <h5 className="mt-2">{textLang.waitText[lang]}</h5>
            <div style={{ paddingTop: '10%', paddingBottom: '10%' }}>
              <Spinner />
            </div>
          </Col>
        </Modal.Body>
      )}
    </Modal>
  );
};

AddLiquidityModal.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  setModalShow: PropTypes.func.isRequired,
  liquidityData: PropTypes.object,
  setAddedLiquidity: PropTypes.func,
  botID: PropTypes.string.isRequired,
  bot: PropTypes.object.isRequired,
  type: PropTypes.string,
  multiBot: PropTypes.bool,
  botList: PropTypes.array,
  isForced: PropTypes.bool,
  addCapitalClickEvent: PropTypes.string
};

export default AddLiquidityModal;
